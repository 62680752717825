import request from '@/utils/request'

//管理后台-查看消息列表
export function communicationsList(data) {
  return request({
    url: "/communications/listPage",
    method: "post",
    data
  })
}

//获取单个回复的数据
export function listDetails(data) {
  return request({
    url: '/communications/detail',
    method: 'get',
    params: data
  })
}

//回复新增
export function communicationsSave(data) {
  return request({
    url: '/communications/sendMessage',
    method: 'post',
    data
  })
}
