<template>
  <div>
    <el-card>
      <span class="span_div">回复状态：</span>
      <el-radio-group v-model="queryInfo.type">
        <el-radio label="">全部</el-radio>
        <el-radio :label="1">已回复</el-radio>
        <el-radio :label="2">未回复</el-radio>
      </el-radio-group>
    </el-card>
    <el-card style="margin-top: 20px">
      <div class="input-with">
        <el-input
          placeholder="请输入企业名称、用户名称"
          v-model="queryInfo.condition"
          class="input-with-select"
        >
          <el-button class="seach" slot="append" @click="search()">搜索</el-button>
        </el-input>
      </div>
      <el-table
        :data="listData"
        stripe
        style="width: 100%"
        class="in_table"
        :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
      >
        <el-table-column label="编号" align="center" type="index"></el-table-column>
        <el-table-column label="微信头像" align="center">
          <template v-slot="{ row }">
            <img :src="row.headImgUrl" alt="" class="img_url" />
          </template>
        </el-table-column>
        <el-table-column label="姓名" prop="nickName" align="center"></el-table-column>
        <el-table-column label="手机号" prop="phone" align="center"></el-table-column>
        <el-table-column label="企业名称" prop="companyFullName" align="center"></el-table-column>
        <el-table-column label="最新咨询问题" prop="content" align="center"></el-table-column>
        <el-table-column
          label="最新咨询时间"
          sortable
          prop="maxCreateTime"
          align="center"
        ></el-table-column>
        <el-table-column
          label="最早咨询时间"
          sortable
          prop="minCreateTime"
          align="center"
        ></el-table-column>
        <el-table-column label="消息回复" align="center">
          <template v-slot="{ row }">
            <el-button type="text" @click="btnClick(row)">回复</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </el-card>
  </div>
</template>

<script>
import { communicationsList } from '@/api/consulting'
import { pagination } from '@/mixins/pagination'
const defaultQueryInfo = Object.freeze({
  condition: '',
  type: '',
  pageNum: 1,
  pageSize: 10
})
export default {
  name: 'ConsultingList',
  mixins: [pagination],
  data() {
    return {
      queryInfo: { ...defaultQueryInfo },
      listData: []
    }
  },
  created() {
    this.search()
  },
  methods: {
    //点击回复
    btnClick(row) {
      this.$router.push({
        path: '/consulting/chat',
        query: { userId: row.id }
      })
    },
    //查询一览数据
    async search() {
      const { data: res } = await communicationsList(this.queryInfo)
      if (res.resultCode === 200) {
        this.listData = res.data.list
        this.total = res.data.total
      }
    }
  }
}
</script>

<style lang="less" scoped>
.span_div {
  font-size: 14px;
  color: #7c7f8e;
  margin-right: 12px;
}

.input-with {
  width: 100%;
  overflow: hidden;

  .input-with-select {
    width: 400px;
    float: right;
  }

  .seach {
    background-color: #448aff;
    color: #fff;
    border-radius: 0;
    border: 1px solid #448aff;
  }
}

.in_table {
  width: 100%;
  margin-top: 20px;

  .img_url {
    width: 42px;
    height: 42px;
    border-radius: 4px;
  }
}

.new_page {
  margin: 20px auto;
  width: 100%;
  text-align: center;
}
</style>
